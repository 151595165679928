export function PreviewBanner() {
  return (
    <div className="sticky top-0 z-50 mx-auto mb-8 mb-8 w-80 bg-black p-4 py-2 text-center text-sm text-white">
      You are in <strong>preview mode</strong>.
      <form action="/api/exit-preview" method="post">
        <button
          type="submit"
          className="btn-primary mt-2 rounded-full bg-teal-300 p-2 text-black"
        >
          Exit Preview Mode <span aria-hidden="true">&rarr;</span>
        </button>
      </form>
    </div>
  );
}
