import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { track, pageView } from "./gtm.client";
import { useCookieContext } from "./useCookies";

export function useTrackPageViews() {
  const { pathname } = useLocation();
  const { cookies } = useCookieContext();

  useEffect(() => {
    if (!pathname) return;
    if (!cookies?.analytics?.accepted) return;
    if (pathname === "/" || pathname === "/home") {
      track(pageView({ category: "Home" }));
    } else if (pathname.match(/^\/who-we-are/)) {
      track(pageView({ category: "Who we are" }));
    } else if (pathname.match(/^\/what-we-do/)) {
      track(pageView({ category: "What we do" }));
    } else if (pathname.match(/^\/insights\/?$/)) {
      track(pageView({ category: "Insights" }));
    } else if (pathname.match(/^\/experts\/?$/)) {
      track(pageView({ category: "Experts" }));
    } else if (
      pathname.match(/^\/search\/?/) ||
      pathname.match(/^\/insights\/.*/) ||
      pathname.match(/^\/experts\/.*/)
    ) {
      // Do nothing. These pages are tracked elsewhere
    } else {
      // There are other pages like tags/$topic and experts/$slug
      track(pageView({ category: "unknown" }));
    }
  }, [pathname, cookies?.analytics?.accepted]);
}

export function TrackPageViews() {
  useTrackPageViews();
  return null;
}
