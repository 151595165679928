import { useEffect } from "react";
import { useCookieContext } from "./useCookies";

export function HotJar() {
  const { cookies } = useCookieContext();
  const { analytics } = cookies;

  useEffect(() => {
    if (!analytics || !analytics.accepted) return;
    if (typeof window == "undefined") return;
    const el = document.createElement("script");
    el.innerText = `(function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:1449614,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.head.prepend(el);
  }, [analytics]);

  return null;
}
