import { Popover, Transition } from "@headlessui/react";
import { useLocation } from "@remix-run/react";
import clsx from "clsx";
import { INavLink } from "./Nav";
import NavWrapper from "./NavWrapper";
import { Fragment } from "react";
import { Carrot } from "../icons";
import SearchTakeover from "../search/SearchTakeover";
import LogoBlackOnWhite from "../icons/svgs/LogoBlackOnWhite";
import LogoWhiteOnBlack from "../icons/svgs/LogoWhiteOnBlack";
import { getBlackPage } from "../utils/getBlackPage";
import NavOrExternalLink from "../shared/NavOrExternalLink";
import { PreventBodyScroll } from "../utils/PreventBodyScroll";

type MobileNavProps = {
  links: INavLink[];
};

export default function MobileNav({ links }: MobileNavProps) {
  const location = useLocation();
  const blackPage = getBlackPage(location.pathname);

  return (
    <Popover as={Fragment}>
      {({ open, close }) => (
        <NavWrapper id="top-nav-mobile" open={open} className="lg:hidden">
          <PreventBodyScroll enabled={open} />
          <div className="flex w-full items-center justify-between">
            <NavOrExternalLink to="/" title="home">
              {open || blackPage ? (
                <LogoWhiteOnBlack id="mobile_logo_black" />
              ) : (
                <LogoBlackOnWhite id="mobile_logo_white" />
              )}
            </NavOrExternalLink>
            <div className="flex w-max justify-between">
              {!open && <SearchTakeover />}
              <Popover.Button
                id="open_close_mobile"
                className="ml-6 outline-none"
              >
                {open ? "CLOSE" : "MENU"}
              </Popover.Button>
            </div>
          </div>
          <Transition
            show={open}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel className="absolute top-mobile-nav left-0 z-10 flex h-[calc(100vh-theme(space[mobile-nav]))] min-h-[550px] w-screen flex-col justify-between bg-black">
              <ul
                id="top-nav-popover-menu-mobile"
                className="mt-[33px] ml-4 mr-4 flex flex-col"
              >
                {links.map((link) => (
                  <Fragment key={link.href}>
                    {link.subroutes ? (
                      <Popover>
                        {({ open: subroutesOpen }) => (
                          <>
                            <Popover.Button
                              id={link.href}
                              className={clsx({
                                flex: true,
                                "w-full": true,
                                "items-center": true,
                                "justify-between": true,
                                "pt-6": true,
                                "pb-6": true,
                                "text-white": true,
                                "outline-none": true,
                                "border-b": !subroutesOpen,
                                "border-solid": !subroutesOpen,
                                "border-white": !subroutesOpen,
                              })}
                            >
                              <span className="text-[16px]">{link.name}</span>
                              <Carrot
                                className={clsx({
                                  "transition-transform": true,
                                  "rotate-180": subroutesOpen,
                                  "duration-150": true,
                                })}
                              />
                            </Popover.Button>
                            <Transition
                              show={subroutesOpen}
                              enter="transition-all duration-300"
                              enterFrom="max-h-0 overflow-hidden"
                              enterTo="max-h-[150px] overflow-hidden"
                              leave="transition-all duration-300"
                              leaveTo="max-h-0 overflow-hidden"
                              leaveFrom="max-h-[150px]"
                            >
                              <Popover.Panel className="flex flex-col">
                                {link.subroutes?.map((route, ix, all) => (
                                  <NavOrExternalLink
                                    to={
                                      route.noPrefix
                                        ? route.href
                                        : link.href + route.href
                                    }
                                    key={route.name}
                                    id={`top-nav-${link.name}`}
                                    className={clsx({
                                      "pb-6": ix !== all.length - 1,
                                      "text-[14px]": true,
                                    })}
                                    prefetch="intent"
                                    onClick={() => close()}
                                  >
                                    {route.name}
                                  </NavOrExternalLink>
                                ))}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    ) : (
                      <NavOrExternalLink
                        to={link.href}
                        key={link.name}
                        id={`top-nav-${link.name}`}
                        className="border-b border-solid border-white pb-6 pt-6 text-[16px]"
                        onClick={() => close()}
                        prefetch="intent"
                      >
                        {link.name}
                      </NavOrExternalLink>
                    )}
                  </Fragment>
                ))}
              </ul>
            </Popover.Panel>
          </Transition>
        </NavWrapper>
      )}
    </Popover>
  );
}
