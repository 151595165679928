import { useEffect, useState } from "react";
import { useCookieContext } from "./useCookies";

declare global {
  interface Window {
    MsCrmMkt: any;
  }
}

const scriptId = "ms-crm";

export function useMsCrm(): Record<string, any> | undefined {
  const [loaded, setLoaded] = useState(
    typeof window == "undefined"
      ? undefined
      : !!document.getElementById(scriptId)
  );

  useEffect(() => {
    if (loaded) return;

    const script = document.createElement("script");
    script.src =
      "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007";
    script.setAttribute("id", scriptId);
    script.setAttribute("data-ignore-prevent-default", "true");
    script.addEventListener("load", () => setLoaded(true));
    document.head.prepend(script);
  }, [loaded]);

  if (typeof window !== "undefined") {
    return window.MsCrmMkt;
  }
}

export default function MicrosoftDynamics() {
  const { cookies } = useCookieContext();
  const { analytics } = cookies;

  const MsCrmMkt = useMsCrm();

  useEffect(() => {
    if (!MsCrmMkt) return;
    MsCrmMkt.reconfigureTracking({ Anonymize: !analytics?.accepted });
  }, [MsCrmMkt, analytics]);

  return (
    <div
      className="d365-mkt-config"
      style={{ display: "none" }}
      data-website-id="bUBIehtNycBeJc0ch8WkVe0auy0sLGxSZ8y8Zh2RRoI"
      data-hostname="b3342bda49be4319bcbadd07a89466cb.svc.dynamics.com"
    />
  );
}
