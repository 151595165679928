import { useTranslation } from "react-i18next";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import Navbar_About from "./assets/Navbar_About.webp";
import Navbar_Approach from "./assets/Navbar_Approach.webp";
import Navbar_Careers from "./assets/Navbar_Careers.webp";
import Navbar_Leadership from "./assets/Navbar_Leadership.webp";
import Navbar_Partnerships from "./assets/Navbar_Partnerships.webp";
import Navbar_FOB from "./assets/Navbar_FOB.webp";

export type SimpleNavLink = {
  name: string;
  href: string;
  image?: string;
  noPrefix?: boolean;
};

export type INavLink = SimpleNavLink & {
  subroutes?: SimpleNavLink[];
};

export default function Nav() {
  const { t } = useTranslation("common");

  // Not listed: Homepage and Search routes
  const links = [
    {
      name: t("WHAT WE DO"),
      href: "/what-we-do",
      subroutes: [
        { name: t("APPROACH"), href: "/approach", image: Navbar_Approach },
        {
          name: t("PARTNERSHIPS"),
          href: "/partnerships",
          image: Navbar_Partnerships,
        },
        {
          name: t("FUTURE OF BRITAIN"),
          href: "/future-of-britain",
          noPrefix: true,
          image: Navbar_FOB,
        },
      ],
    },
    { name: t("INSIGHTS"), href: "/insights" },
    { name: t("EXPERTS"), href: "/experts" },
    {
      name: t("WHO WE ARE"),
      href: "/who-we-are",
      subroutes: [
        { name: t("ABOUT US"), href: "/about", image: Navbar_About },
        {
          name: t("EXECUTIVE LEADERSHIP"),
          href: "/executive-leadership",
          image: Navbar_Leadership,
        },
        { name: t("CAREERS"), href: "/careers", image: Navbar_Careers },
      ],
    },
  ];

  return (
    <>
      <DesktopNav links={links} />
      <MobileNav links={links} />
    </>
  );
}
