import { useEffect } from "react";
import { useCookieContext } from "./useCookies";

export type UseGTMScriptProps = {
  gtmId?: string | null | undefined;
};

export function useGTMScript({ gtmId }: UseGTMScriptProps) {
  const { cookies } = useCookieContext();
  const { analytics } = cookies;

  useEffect(() => {
    if (!gtmId) return;
    if (!analytics || !analytics.accepted) return;

    if (typeof window == "undefined") return;
    // Avoids hydration errors
    const el = document.createElement("script");
    el.innerText = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmId}');
          `;
    document.head.prepend(el);
  }, [gtmId, analytics]);
}

export const GoogleTagManagerNoScript = ({ gtmId }: UseGTMScriptProps) => {
  const { cookies } = useCookieContext();
  const { analytics } = cookies;

  useGTMScript({ gtmId });

  if (!gtmId) return null;

  return (
    <noscript>
      {analytics && analytics.accepted && (
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          className="invisible hidden"
        ></iframe>
      )}
    </noscript>
  );
};
